import React, { useState } from "react"

export default function HeaderMenuDemolition({ isMenuOpen, toggleMenu }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState)
  }

  return (
    <div
      className="collapse navbar-collapse justify-content-end"
      id="navbarSupportedContent"
    >
      <div className={`mobile-content ${isMenuOpen ? "show" : ""}`}>
        <div className="mobile-header">
          <button
            type="button"
            className="modal-closer"
            onClick={toggleMenu}
            aria-label="Close"
          >
            <span>X</span>
          </button>
        </div>
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link nav-link-demolition" href="/">
              Find Demolition Contractors
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-link-demolition" href="/barn-demolition">
              Barn Demolition
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-link-demolition" href="/chimney-removal">
              Chimney Removal
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link nav-link-demolition"
              href="/commercial-demolition"
            >
              Commercial Demolition
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link nav-link-demolition"
              href="/concrete-removal"
            >
              Concrete Removal
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link nav-link-demolition"
              href="/deck-and-fence-removal"
            >
              Deck and Fence Removal
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link nav-link-demolition"
              href="/house-and-garage-demolition"
            >
              House and Garage Demolition
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link nav-link-demolition"
              href="/interior-demolition"
            >
              Interior Demolition
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link nav-link-demolition"
              href="/mobile-home-demolition"
            >
              Mobile Home Removal
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-link-demolition" href="/pool-removal">
              Pool Removal
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-link-demolition" href="/tank-removal">
              Tank Removal
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-link-demolition" href="/blog">
              Demolition Tips & Advice
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link nav-link-demolition"
              href="/pro/guide/demolition-marketing"
            >
              Join as a Pro
            </a>
          </li>
        </ul>
      </div>

      {!isMenuOpen && (
        <ul className="navbar-nav desktop">
          <li className="nav-item dropdown">
            <a
              id="hm_mainopt"
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={isDropdownOpen}
              onClick={toggleDropdown}
            >
              Find Pros
            </a>
            <ul
              id="dropdownHeader"
              className={`dropdown-menu dropdown-menu-right ${
                isDropdownOpen ? "show" : ""
              }`}
              aria-labelledby="hm_mainopt"
            >
              <li>
                <a className="dropdown-item" href="/">
                  All Demolition Contractors
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/barn-demolition">
                  Barn Demolition
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/chimney-removal">
                  Chimney Removal
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/commercial-demolition">
                  Commercial Demolition
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/concrete-removal">
                  Concrete Removal
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/deck-and-fence-removal">
                  Deck and Fence Removal
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/house-and-garage-demolition"
                >
                  House and Garage Demolition
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/interior-demolition">
                  Interior Demolition
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/mobile-home-demolition">
                  Mobile Home Removal
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/pool-removal">
                  Pool Removal
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/tank-removal">
                  Tank Removal
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-link-demolition" href="/blog">
              Tips & Advice
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link nav-link-demolition"
              href="/pro/guide/demolition-marketing"
            >
              Are You a Demolition Pro?
            </a>
          </li>
        </ul>
      )}
    </div>
  )
}
